import React from "react";
import type Node from "react";
import { Layout, Markdown } from "../components";

type Props = {
    pageContext: Object,
    location: Object
}

export default (props: Props): Node => {
    const categories = props.pageContext.categories.map(item => item.category)
    const pages = props.pageContext.pages.map(item => ({
        next: item.next, previous: item.previous, page: item.post
    }))
    const menuLinks = props.menuLinks
    const { html } = props.pageContext.page.post
    const { title, description } = props.pageContext.page.post.frontmatter
    return (
      <Layout
        title={title}
        description={description}
        categories={categories}
        page={props.pageContext.page.post}
        pages={pages}
        menuLinks={menuLinks}
        context={props.pageContext}
        location={props.location}
      >
          <Markdown dangerouslySetInnerHTML={{__html: html}} />
      </Layout>
    )
}
